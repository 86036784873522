import { css } from '@emotion/react';
import React, { useRef, useState } from 'react';
import Row from 'src/lib/layout/Row';
import colors from 'src/lib/theme/colors';
import { Text } from '../Text';

type SliderProps = {
  min?: number;
  max?: number;
  value?: number;
  onValue: (value: number) => void;
};

const Slider = ({ min = 0, max = 400, value = 50, onValue }: SliderProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [tempValue, setTempValue] = useState(value);
  const [background, setBackground] = useState(
    `linear-gradient(to right, ${colors.darkGray} 0%, ${colors.darkGray} ${(value * 100) / max}%, ${
      colors.lightGray
    } ${(value * 100) / max}%, ${colors.lightGray} 100%)`,
  );
  const onChange = (value: string) => {
    if (!isNaN(Number(value))) {
      setTempValue(Number(value));
      setBackground(
        `linear-gradient(to right, ${colors.darkGray} 0%, ${colors.darkGray} ${
          (Number(value) * 100) / max
        }%, ${colors.lightGray} ${(Number(value) * 100) / max}%, ${colors.lightGray} 100%)`,
      );
    }
  };
  return (
    <div
      css={css`
        width: 100%;
        padding: 0 2px;
        height: max-content;
      `}
    >
      <label>
        <Text variant="Ingress">{tempValue}</Text>
      </label>
      <div>
        <input
          ref={ref}
          onChange={(e) => onChange(e.target.value)}
          defaultValue={value}
          onMouseUp={() => ref && ref.current && onValue(Number(ref.current.value) ?? 0)}
          type="range"
          max={max}
          min={min}
          css={css`
            width: 100%;
            height: 5px;
            -webkit-appearance: none;
            background: ${background};
            ::-webkit-slider-runnable-track {
              height: 5px;
              -webkit-appearance: none;
              color: #13bba4;
            }
            ::-webkit-slider-thumb {
              margin-top: -8px;
              width: 20px;
              -webkit-appearance: none;
              height: 20px;
              background: url(icons/slider-icon.png);
              /* box-shadow: -80px 0 0 80px ${colors.darkGray}; */
            }
            ::-moz-range-progress {
              background-color: ${colors.darkGray};
            }
            ::-moz-range-track {
              background-color: ${colors.lightGray};
            }
            ::-ms-fill-lower {
              background-color: ${colors.darkGray};
            }
            ::-ms-fill-upper {
              background-color: ${colors.lightGray};
            }
          `}
        />
        <Row
          justify="space-between"
          css={css`
            padding: 0 2px;
          `}
        >
          <Text variant="Details">{min}</Text>
          <Text variant="Details">{max}</Text>
        </Row>
      </div>
    </div>
  );
};

export default Slider;
