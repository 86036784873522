import { css } from '@emotion/react';
import React from 'react';
import colors from '../theme/colors';

type LoaderProps = {
  height?: number;
  width?: number;
};

const Loader = ({ height = 30, width = 60 }: LoaderProps) => {
  return (
    <div
      css={css`
        width: ${width}px;
        height: ${height}px;
        display: flex;
        align-items: center;
      `}
    >
      <svg
        version="1.1"
        id="L4"
        x="100%"
        y="100%"
        viewBox="-25 35 100 30"
        enableBackground="new 0 0 0 0"
      >
        <circle fill={colors.textBlack} stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill={colors.textBlack} stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill={colors.textBlack} stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
  );
};

export default Loader;
