import { css } from '@emotion/react';
import React from 'react';
import Icon from './icon/Icon';
import { Text } from './Text';
type TagProps = {
  title: string;
  onClick?: () => void;
  onClose?: () => void;
  category?: string;
};

const Tag = ({ title, onClick, onClose, category }: TagProps) => {
  return (
    <div>
      {category && <Text variant="CaptionSmall">{category}</Text>}
      <div
        css={css`
          background-color: #fff;
          padding: 12px 20px;
          display: flex;
          gap: 12px;
          align-items: center;
          white-space: nowrap;
          width: fit-content;
        `}
        onClick={onClick}
      >
        <Icon
          icon="Close"
          color="#000000"
          onClick={onClose}
          css={css`
            cursor: pointer;
          `}
        />
        <Text variant="Body">{title}</Text>
      </div>
    </div>
  );
};

export default Tag;
