import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import colors from 'src/lib/theme/colors';
import { Text } from '../Text';

type CheckboxProps = {
  label: string;
  onValue?: (value: boolean) => void;
  selected?: boolean;
  reverseOrder?: boolean;
};

const Checkbox = ({ label, onValue, selected = false, reverseOrder = false }: CheckboxProps) => {
  const [checked, setChecked] = useState(selected);
  useEffect(() => {
    setChecked(selected);
  }, [selected]);
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        width: 100%;
        ${reverseOrder &&
        css`
          flex-direction: row-reverse;
          justify-content: space-between;
        `}
      `}
      onClick={() => {
        setChecked(!checked);
        onValue && onValue(!checked);
      }}
    >
      <input
        onChange={(e) => {
          setChecked(e.target.checked);
          onValue && onValue(e.target.checked);
        }}
        checked={checked}
        type="checkbox"
        css={css`
          align-self: baseline;
          position: relative;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          color: black;
          border: 1px solid ${colors.type};
          border-radius: 0px;
          appearance: none;
          outline: 0;
          cursor: pointer;
          transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
          &::before {
            position: absolute;
            content: '';
            display: block;
            top: 2px;
            left: 7px;
            width: 8px;
            height: 14px;
            border-style: solid;
            border-color: white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
          }
          &:checked {
            color: white;
            border-color: ${colors.type};
            background: ${colors.type};
            &::before {
              opacity: 1;
            }
            ~ label::before {
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
          }
        `}
      />
      <label
        css={css`
          white-space: wrap;
          width: 100%;
          position: relative;
          color: ${colors.darkGray};
          appearance: none;
          padding-left: 8px;
          outline: 0;
          cursor: pointer;
          transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
          &::before {
            position: absolute;
            content: '';
            display: block;
            top: 2px;
            left: 7px;
            width: 8px;
            height: 14px;
            border-style: solid;
            border-color: black;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
          }
          &:checked {
            color: white;
            border-color: green;
            background: green;
            &::before {
              opacity: 1;
            }
            ~ label::before {
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
          }
        `}
      >
        <Text
          css={css`
            line-height: 1.5 !important;
          `}
          variant="Body"
        >
          {label}
        </Text>
      </label>
    </div>
  );
};

export default Checkbox;
