const tagNameByValue: Record<string, string> = {
  audioAndVisual: 'Audio and Visual',
  catering: 'Catering',
  decorist: 'Decorist',
  equipmentTableArtFurnitureAndPlantHire: 'Equipment, Table art, Furniture & Plant Hire',
  florist: 'Florist',
  giveaways: 'Giveaways',
  guides: 'Guides',
  hairAndMakeup: 'Hair & Makeup',
  localContact: 'Local Contact',
  personnelSecurityAndSupportStaff: 'Personnel, Security & Support Staff',
  photoVideo: 'Photo/Video',
  productionCompany: 'Production company',
  software: 'Software',
  sportSupplies: 'Sport supplies',
  stationaryGraphics: 'Stationary/graphics',
  tent: 'Tent',
  transportation: 'Transportation',
  localOnly: 'Local Only',
  flexibleLocation: 'Flexible Location',
  culturalAndArt: 'Cultural & Art',
  events: 'Events',
  foodAndWine: 'Food & Wine',
  landmarksAndMonuments: 'Landmarks and Monuments',
  parksAndGardens: 'Parks & Gardens',
  performingArts: 'Performing Arts',
  sport: 'Sport',
  spa: 'Spa',
  teamBuilding: 'Team Activities',
  achinglyHip: 'Achingly Hip',
  budgetChic: 'Budget Chic',
  business: 'Business',
  neighbourhoodVibe: 'Neighbourhood vibe',
  oldSchool: 'Old School',
  elegant: 'Elegant',
  modern: 'Modern',
  classic: 'Classic',
  traditional: 'Traditional',
  formal: 'Formal',
  relaxed: 'Relaxed',
  romantic: 'Romantic',
  luxury: 'Luxury',
  anchientChic: 'Anchient Chic',
  morning: 'Morning',
  daytime: 'Daytime',
  evening: 'Evening',
  late: 'Late',
  '24Hours': '24 hours',
  veganFriendly: 'Vegan friendly',
  parking: 'Parking',
  pool: 'Pool',
  cocktails: 'Cocktails',
  suits: 'Suits',
  hairDryers: 'Hair Dryers',
  wedding: 'Wedding',
  conference: 'Conference',
  buyOut: 'Buy out',
  stay: 'Stay',
  afterSki: 'After Ski',
  weddingCeremony: 'Wedding Ceremony',
  weddingDinner: 'Wedding Dinner',
  party: 'Party',
  corporate: 'Corporate',
  dinner: 'Dinner ',
  dinnerParties: 'Dinner Parties',
  acoustics: 'Acoustics',
  festival: 'Festival',
  funural: 'Funural',
  bands: 'Bands',
  choir: 'Choir',
  dJ: 'DJ',
  danceGroups: 'Dance Groups',
  entertainmentForTheGuests: 'Entertainment for the guests',
  managementBooking: 'Management/Booking',
  muscicians: 'Muscicians',
  singers: 'Singers',
  speakers: 'Speakers',
  stageEntertainment: 'Stage Entertainment',
  restaurant: 'Restaurant',
  hotel: 'Hotel',
  eventVenue: 'Event Venue',
  bar: 'Bar',
  cafe: 'Cafe',
  club: 'Club',
  sight: 'Sight',
  gastropub: 'Gastropub',
  cocktail: 'Cocktail',
  'late-Night': 'Late-night',
  rooftop: 'Rooftop',
  terraceBar: 'Terrace Bar',
  neighbourhoodBar: 'Neighbourhood bar',
  wineBar: 'Wine Bar',
  sportsBar: 'Sports Bar',
  hotelBar: 'Hotel Bar',
  pub: 'Pub',
  coffeeShop: 'Coffee shop',
  bakery: 'Bakery',
  confectionery: 'Confectionery',
  liveMusic: 'Live Music',
  nightClub: 'Night Club',
  casino: 'Casino',
  resort: 'Resort',
  boutiqueHotel: 'Boutique Hotel',
  conferenceHotel: 'Conference hotel',
  contemporary: 'Contemporary',
  luxuryChain: 'Luxury Chain',
  inn: 'Inn',
  castle: 'Castle',
  fineDining: 'Fine Dining',
  italian: 'Italian',
  japanese: 'Japanese',
  seaFood: 'Sea Food',
  steak: 'Steak',
  french: 'French',
  thai: 'Thai',
  fusion: 'Fusion',
  localCusine: 'Local Cusine',
  comfortFood: 'Comfort Food',
  monument: 'Monument',
  park: 'Park',
  uniqueSpots: 'Unique Spots',
  venue: 'Venues',
  supplier: 'Suppliers',
  activity: 'Activities',
  entertainment: 'Entertainment',
  myCollections: 'My Collections',
  allCollections: 'All Collections',
  '1': '$',
  '2': '$$',
  '3': '$$$',
  '3NumberOfStars': '★★★',
  '4NumberOfStars': '★★★★',
  '5NumberOfStars': '★★★★★',
};

export default tagNameByValue;
