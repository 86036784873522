import { css } from '@emotion/react';
import React from 'react';

type RowProps = {
  align?: 'center' | 'flex-start' | 'flex-end' | 'top';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'unset';
  gap?: number;
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse' | 'unset';
};

const Row = ({
  children,
  align = 'center',
  justify = 'unset',
  wrap = 'unset',
  gap,
  ...rest
}: RowProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: ${align};
        justify-content: ${justify};
        flex-wrap: ${wrap};
        ${gap &&
        css`
          gap: ${gap}px;
        `};
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Row;
