import { AllFiltersType } from 'src/pages/cards';
import { LocationOption } from '../forms/SearchSelect';

export const SUPPLIER_TYPE_CHECKBOXES = [
  {
    label: 'Audio and Visual',
    value: 'audioAndVisual',
    selected: false,
  },
  { label: 'Catering', value: 'catering', selected: false },
  { label: 'Decorist', value: 'decorist', selected: false },
  {
    label: 'Equipment, Table art, Furniture & Plant Hire',
    value: 'equipmentTableArtFurnitureAndPlantHire',
    selected: false,
  },
  { label: 'Florist', value: 'florist', selected: false },
  { label: 'Giveaways', value: 'giveaways', selected: false },
  { label: 'Guides', value: 'guides', selected: false },
  { label: 'Hair & Makeup', value: 'hairAndMakeup', selected: false },
  { label: 'Local Contact', value: 'localContact', selected: false },
  {
    label: 'Personnel, Security & Support Staff',
    value: 'personnelSecurityAndSupportStaff',
    selected: false,
  },
  { label: 'Photo/Video', value: 'photoVideo', selected: false },
  {
    label: 'Production company',
    value: 'productionCompany',
    selected: false,
  },
  { label: 'Software', value: 'software', selected: false },
  { label: 'Sport supplies', value: 'sportSupplies', selected: false },
  {
    label: 'Stationary/graphics',
    value: 'stationaryGraphics',
    selected: false,
  },
  { label: 'Tent', value: 'tent', selected: false },
  { label: 'Transportation', value: 'transportation', selected: false },
];

export const AVILABLILLITY_CHECKBOXES = [
  { label: 'Local Only', value: 'localOnly', selected: false },
  {
    label: 'Flexible Location',
    value: 'flexibleLocation',
    selected: false,
  },
];

export const ACTIVITY_TYPE_CHECKBOXES = [
  { label: 'Cultural & Art', value: 'culturalAndArt', selected: false },
  { label: 'Events', value: 'events', selected: false },
  { label: 'Food & Wine', value: 'foodAndWine', selected: false },
  {
    label: 'Landmarks and Monuments',
    value: 'landmarksAndMonuments',
    selected: false,
  },
  {
    label: 'Parks & Gardens',
    value: 'parksAndGardens',
    selected: false,
  },
  {
    label: 'Performing Arts',
    value: 'performingArts',
    selected: false,
  },
  { label: 'Sport', value: 'sport', selected: false },
  { label: 'Spa', value: 'spa', selected: false },
  { label: 'Team Activities', value: 'teamBuilding', selected: false },
];

export const HOTEL_ATMOSPHERE_CHECKBOXES = [
  { label: 'Modern', value: 'modern', selected: false },
  { label: 'Traditional', value: 'traditional', selected: false },
  { label: 'Formal', value: 'formal', selected: false },
  { label: 'Relaxed', value: 'relaxed', selected: false },
  { label: 'Romantic', value: 'romantic', selected: false },
  { label: 'Luxury', value: 'luxury', selected: false },
  { label: 'Anchient Chic', value: 'anchientChic', selected: false },
  { label: 'Achingly Hip', value: 'achinglyHip', selected: false },
  { label: 'Budget Chic', value: 'budgetChic', selected: false },
];

export const RESTAURANT_ATMOSPHERE_CHECKBOXES = [
  { label: 'Achingly Hip', value: 'achinglyHip', selected: false },
  { label: 'Budget Chic', value: 'budgetChic', selected: false },
  { label: 'Business', value: 'business', selected: false },
  {
    label: 'Neighbourhood vibe',
    value: 'neighbourhoodVibe',
    selected: false,
  },
  { label: 'Old School', value: 'oldSchool', selected: false },
  { label: 'Formal', value: 'formal', selected: false },
  { label: 'Elegant', value: 'elegant', selected: false },
  { label: 'Contemporary', value: 'contemporary', selected: false },
  { label: 'Iconic', value: 'iconic', selected: false },
  { label: 'Dress up', value: 'dressUp', selected: false },
  { label: 'Fusion', value: 'fusion', selected: false },
];

export const CAFE_ATMOSPHERE_CHECKBOXES = [
  { label: 'Modern', value: 'modern', selected: false },
  {
    label: 'Neighbourhood vibe',
    value: 'neighbourhoodVibe',
    selected: false,
  },
  { label: 'Classic', value: 'classic', selected: false },
];

export const BAR_ATMOSPHERE_CHECKBOXES = [
  { label: 'Achingly Hip', value: 'achinglyHip', selected: false },
  { label: 'Budget Chic', value: 'budgetChic', selected: false },
  { label: 'Business', value: 'business', selected: false },
  {
    label: 'Neighbourhood vibe',
    value: 'neighbourhoodVibe',
    selected: false,
  },
  { label: 'Old School', value: 'oldSchool', selected: false },
  { label: 'Elegant', value: 'elegant', selected: false },
];

export const ATMOSPHERE_CHECKBOXES = [
  ...HOTEL_ATMOSPHERE_CHECKBOXES,
  ...RESTAURANT_ATMOSPHERE_CHECKBOXES,
  ...CAFE_ATMOSPHERE_CHECKBOXES,
  ...BAR_ATMOSPHERE_CHECKBOXES,
].filter(({ value }, index, self) => self.findIndex((t) => t.value === value) === index);
export const TIME_OF_DAY_CHECKBOXES = [
  { label: 'Morning', value: 'morning', selected: false },
  { label: 'Daytime', value: 'daytime', selected: false },
  { label: 'Evening', value: 'evening', selected: false },
  { label: 'Late', value: 'late', selected: false },
  { label: '24 hours', value: '24Hours', selected: false },
];

export const STARS_CHECKBOXES = [
  { label: '★★★', value: '3', selected: false },
  { label: '★★★★', value: '4', selected: false },
  { label: '★★★★★', value: '5', selected: false },
];

export const FACILITIES_CHECKBOXES = [
  { label: 'Vegan friendly', value: 'veganFriendly', selected: false },
  { label: 'Parking', value: 'parking', selected: false },
  { label: 'Pool', value: 'pool', selected: false },
  { label: 'Cocktails', value: 'cocktails', selected: false },
  { label: 'Suits', value: 'suits', selected: false },
  { label: 'Hair Dryers', value: 'hairDryers', selected: false },
];
export const VENUE_OCCASION_CHECKBOXES = [
  { label: 'Wedding', value: 'wedding', selected: false },
  { label: 'Conference', value: 'conference', selected: false },
  { label: 'Sport', value: 'sport', selected: false },
  { label: 'Buy out', value: 'buyOut', selected: false },
  { label: 'Stay', value: 'stay', selected: false },
  { label: 'Dinner parties', value: 'dinnerParties', selected: false },
];

export const ENTERTEINMENT_OCCASION_CHECKBOXES = [
  { label: 'After Ski', value: 'afterSki', selected: false },
  {
    label: 'Wedding Ceremony',
    value: 'weddingCeremony',
    selected: false,
  },
  { label: 'Wedding Dinner', value: 'weddingDinner', selected: false },
  { label: 'Party', value: 'party', selected: false },
  { label: 'Corporate', value: 'corporate', selected: false },
  { label: 'Dinner ', value: 'dinner', selected: false },
  { label: 'Acoustics', value: 'acoustics', selected: false },
  { label: 'Festival', value: 'festival', selected: false },
  { label: 'Funural', value: 'funural', selected: false },
];

export const ENTERTEINMENT_TYPE_CHECKBOXES = [
  { label: 'Bands', value: 'bands', selected: false },
  { label: 'Choir', value: 'choir', selected: false },
  { label: 'DJ', value: 'dJ', selected: false },
  { label: 'Dance Groups', value: 'danceGroups', selected: false },
  {
    label: 'Entertainment for the guests',
    value: 'entertainmentForTheGuests',
    selected: false,
  },
  {
    label: 'Management/Booking',
    value: 'managementBooking',
    selected: false,
  },
  { label: 'Muscicians', value: 'muscicians', selected: false },
  { label: 'Singers', value: 'singers', selected: false },
  { label: 'Speakers', value: 'speakers', selected: false },
  {
    label: 'Stage Entertainment',
    value: 'stageEntertainment',
    selected: false,
  },
];

export const PRICE_RANGE_CHECKBOXES = [
  { label: '$', value: '1', selected: false },
  { label: '$$', value: '2', selected: false },
  { label: '$$$', value: '3', selected: false },
];

export const VENUE_TYPE_CHECKBOXES = [
  { label: 'Restaurant', value: 'restaurant', selected: false },
  { label: 'Hotel', value: 'hotel', selected: false },
  { label: 'Event Venue', value: 'eventVenue', selected: false },
  { label: 'Bar', value: 'bar', selected: false },
  { label: 'Cafe', value: 'cafe', selected: false },
  { label: 'Club', value: 'club', selected: false },
  { label: 'Sight', value: 'sight', selected: false },
];

export const VENUE_HOTEL_TYPE_CHECKBOXES = [
  { label: 'Resort', value: 'resort', selected: false },
  { label: 'Boutique Hotel', value: 'boutiqueHotel', selected: false },
  {
    label: 'Conference hotel',
    value: 'conferenceHotel',
    selected: false,
  },
  { label: 'Contemporary', value: 'contemporary', selected: false },
  { label: 'Classic', value: 'classic', selected: false },
  { label: 'Luxury Chain', value: 'luxuryChain', selected: false },
  { label: 'Budget Chic', value: 'budgetChic', selected: false },
  { label: 'Inn', value: 'inn', selected: false },
  { label: 'Castle', value: 'castle', selected: false },
];

export const VENUE_RESTAURANT_TYPE_CHECKBOXES = [
  { label: 'Fine Dining', value: 'fineDining', selected: false },
  { label: 'Italian', value: 'italian', selected: false },
  { label: 'Japanese', value: 'japanese', selected: false },
  { label: 'Sea Food', value: 'seaFood', selected: false },
  { label: 'Steak', value: 'steak', selected: false },
  { label: 'French', value: 'french', selected: false },
  { label: 'Thai', value: 'thai', selected: false },
  { label: 'Fusion', value: 'fusion', selected: false },
  { label: 'Local Cusine', value: 'localCusine', selected: false },
  { label: 'Comfort Food', value: 'comfortFood', selected: false },
];

export const VENUE_CLUB_TYPE_CHECKBOXES = [
  { label: 'Live Music', value: 'liveMusic', selected: false },
  { label: 'Night Club', value: 'nightClub', selected: false },
  { label: 'Casino', value: 'casino', selected: false },
];

export const VENUE_CAFE_TYPE_CHECKBOXES = [
  { label: 'Coffee shop', value: 'coffeeShop', selected: false },
  { label: 'Bakery', value: 'bakery', selected: false },
  { label: 'Confectionery', value: 'confectionery', selected: false },
];

export const VENUE_BAR_TYPE_CHECKBOXES = [
  { label: 'Gastropub', value: 'gastropub', selected: false },
  { label: 'Cocktail', value: 'cocktail', selected: false },
  { label: 'Late-night', value: 'late-Night', selected: false },
  { label: 'Rooftop', value: 'rooftop', selected: false },
  { label: 'Terrace Bar', value: 'terraceBar', selected: false },
  {
    label: 'Neighbourhood bar',
    value: 'neighbourhoodBar',
    selected: false,
  },
  { label: 'Wine Bar', value: 'wineBar', selected: false },
  { label: 'Sports Bar', value: 'sportsBar', selected: false },
  { label: 'Hotel Bar', value: 'hotelBar', selected: false },
  { label: 'Pub', value: 'pub', selected: false },
];

export const VENUE_SIGHT_TYPE_CHECKBOXES = [
  { label: 'Monument', value: 'monument', selected: false },
  { label: 'Park', value: 'park', selected: false },
  { label: 'Unique Spots', value: 'uniqueSpots', selected: false },
];

export const VENUE_SUBCATEGORY_CHECKBOXES = [
  ...VENUE_BAR_TYPE_CHECKBOXES,
  ...VENUE_CAFE_TYPE_CHECKBOXES,
  ...VENUE_CLUB_TYPE_CHECKBOXES,
  ...VENUE_HOTEL_TYPE_CHECKBOXES,
  ...VENUE_RESTAURANT_TYPE_CHECKBOXES,
  ...VENUE_SIGHT_TYPE_CHECKBOXES,
];

export const CATEGORY_TYPE_RADIO_BUTTONS = [
  { label: 'Venues', value: 'venue' },
  { label: 'Suppliers', value: 'supplier' },
  { label: 'Activities', value: 'activity' },
  { label: 'Entertainment', value: 'entertainment' },
];
export const CATEGORY_COLLECTION_RADIO_BUTTONS = [
  { label: 'My Collections', value: 'myCollections' },
  { label: 'All Collections', value: 'allCollections' },
];

export const removeUnusedAndTransform = (allFilters: AllFiltersType) => {
  const filtersTransformed: { [key: string]: string[] } = {};
  for (const key in allFilters) {
    filtersTransformed[key] = allFilters[key]
      .filter((filter) => filter.selected)
      .map(({ value }) => value);
  }
  return filtersTransformed;
};

export const createLocationCheckboxes = (
  locationSelectOptions: LocationOption[],
  location?: LocationOption,
) =>
  locationSelectOptions.map((o) => ({
    label: o.isCountryEntry ? o.country : o.city!,
    value: o.isCountryEntry ? o.country : o.city!,
    options: o,
    selected: JSON.stringify(location) === JSON.stringify(o),
  }));
