import { useUser } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import React, { FC, ComponentType, useEffect } from 'react';
import { hasAccess } from './permissions';
import { User } from './types';

const withAuthRequired = <P extends object>(
  Component: ComponentType<P>,
  route: string,
): FC<P & { slug?: string | string[] }> => {
  const checkAuth = (
    props: P & { slug?: string | string[]; docId?: string; isAuthorizedWithPassword?: boolean },
  ): JSX.Element => {
    const router = useRouter();
    const userContext = useUser();
    const user: User | undefined = userContext.user;
    const isAuthorized =
      (user
        ? hasAccess(route, props.slug, props.docId, user['https://www.offpiste.no/permissions'])
        : false) || props.isAuthorizedWithPassword;
    useEffect(() => {
      if (!isAuthorized && !userContext.isLoading) {
        router.push('/api/auth/login');
      }
    }, [userContext]);
    return isAuthorized ? <Component {...props} /> : <></>;
  };
  return checkAuth;
};
export default withAuthRequired;
