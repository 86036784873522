import { css } from '@emotion/react';
import React from 'react';
import { DESKTOP } from 'src/lib/theme/breakpoints';
import colors from 'src/lib/theme/colors';
import Icon, { IconType } from '../icon/Icon';
import Loader from '../Loader';
import { Text } from '../Text';
type ButtonProps = {
  label: string;
  icon?: IconType;
  iconStart?: boolean;
  disabled?: boolean;
  variant?: 'outline' | 'text' | 'primary' | 'secondary';
  onClick?: () => void;
  loading?: boolean;
  removePhoneLable?: boolean;
};
const buttonVariants = {
  outline: css`
    border: 1px solid ${colors.type};
  `,
  text: css`
    border: none;
  `,
  primary: css`
    border: 1px solid ${colors.type};
    background-color: transparent;
  `,
  secondary: css`
    border: 1px solid ${colors.type};
    background-color: transparent;
  `,
};
const Button = ({
  label,
  icon,
  iconStart = true,
  onClick,
  disabled,
  variant = 'outline',
  loading = false,
  removePhoneLable,
  ...rest
}: ButtonProps & React.HTMLAttributes<HTMLElement>) => {
  return (
    <div {...rest}>
      <button
        css={css`
          background-color: transparent;
          padding: 11px 24px;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: ${colors.type};
          ${buttonVariants[variant]}
          width: 100%;
          gap: 12px;
        `}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && iconStart && <Icon icon={icon} color={colors.type} size={16} />}
        {loading ? (
          <Loader />
        ) : (
          <Text
            css={
              removePhoneLable &&
              css`
                display: none;
                @media (min-width: ${DESKTOP}px) {
                  display: block;
                }
              `
            }
            variant="Body"
          >
            {label}
          </Text>
        )}
        {icon && !iconStart && (
          <Icon
            css={css`
              margin-left: 12px;
            `}
            icon={icon}
            color={colors.type}
          />
        )}
      </button>
    </div>
  );
};

export default Button;
