import { sanityClient } from './sanityClient';
import ImageUrlBuilder from '@sanity/image-url';
import { SanityImageObject } from './types';
import axios from 'axios';

export const imageUrlBuilder = ImageUrlBuilder(sanityClient);
export function getSanityImageURL(
  image: SanityImageObject,
  width: number = 1000,
  height: number = 800,
) {
  if (!image) {
    return '';
  } else {
    try {
      const url = imageUrlBuilder
        .image(image)
        .fit('crop')
        .size(Math.round(width), Math.round(height))
        .url();
      return url;
    } catch (err) {
      return '';
    }
  }
}

export const uploadImageAndCreateDoc = async (image: File) => {
  const formData = new FormData();
  formData.append('image', image);

  const response = await axios.post('/api/assets', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return {
    _type: 'image',
    asset: {
      _ref: response.data.imageId,
      _type: 'reference',
    },
  };
};
