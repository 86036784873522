import SanityClient from '@sanity/client';
const dataset =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
    ? process.env.NEXT_PUBLIC_SANITY_DATASET_DEV
    : process.env.NEXT_PUBLIC_SANITY_DATASET_PROD;
const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ?? '38dd5ff9';
export const sanityClient = SanityClient({
  projectId,
  dataset: dataset ?? 'op1-development',
  apiVersion: '2021-03-25',
  useCdn: true,
});
